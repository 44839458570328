import { type Libraries, useJsApiLoader } from '@react-google-maps/api';
import { useEnv } from './env.ts';

const libraries = ['places'] as Libraries;

export function useGoogleMapJsLoader() {
	const { GOOGLE_MAPS_API } = useEnv();
	if (typeof window === 'undefined')
		return { isLoaded: false, loadError: null };

	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: GOOGLE_MAPS_API,
		libraries,
	});
}
